function hasEmptyValue (obj) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const value = obj[key]
      // 判断属性值是否为空
      if (value === null || value === undefined || value === '') {
        return true
      }
    }
  }
  return false
}

function ObjForIn (newObj, oldObj) {
  for (const key in newObj) {
    if (Object.prototype.hasOwnProperty.call(newObj, key) && oldObj[key] !== undefined) {
      newObj[key] = oldObj[key]
    }
  }
  return newObj
}

function formatAmountWithDecimal (amount) {
  if (!amount) return ''
  const parts = amount.toString().split('.')
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return parts.join('.')
}

export default {
  hasEmptyValue,
  ObjForIn,
  formatAmountWithDecimal
}
