<template>
  <div class="draw">
    <!-- 中法切换   -->
    <!--    <van-dropdown-menu :overlay="false">-->
    <!--      <van-dropdown-item ref="langMenu" >-->
    <!--        <template v-slot:title>-->
    <!--          &lt;!&ndash; 自定义头部内容 &ndash;&gt;-->
    <!--          <div class="title-item">-->
    <!--            <img :src="activeText.national" class="title-img"/>-->
    <!--            <span>{{ activeText.text }}</span>-->
    <!--          </div>-->
    <!--        </template>-->
    <!--        <div class="language-menu">-->
    <!--          <div class="language-item" @click="changeMenu('zh')">-->
    <!--            <img src="../../assets/lang/zh.png" class="item-img"/>-->
    <!--            <span>中文</span>-->
    <!--          </div>-->
    <!--          <div class="language-item" @click="changeMenu('fr')">-->
    <!--            <img src="../../assets/lang/fr.png" class="item-img"/>-->
    <!--            <span>Frangais</span>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </van-dropdown-item>-->
    <!--    </van-dropdown-menu>-->
    <!-- 长图 -->
    <img :src="activeText.bg" alt="Long Image" class="long-image" ref="image"/>
    <!-- 老虎机 -->
    <div class="machine-pos" v-if="isShowMachine">
      <SlotMachine
          ref="myLucky"
          :width="slotWidth"
          :height="slotHeight"
          :blocks="blocks"
          :slots="slots"
          :prizes="prizes"
          @end="endCallback"
          :default-config="defaultConfig">
      </SlotMachine>
    </div>
    <!-- 太极旋转 -->
    <div class="rotate-pos" @click="getPrizeRewardCode">
      <img src="../../assets/img/tj.png" class="rotate-img"
           :style="{ transform: 'rotate(' + rotationDegree + 'deg)' }"/>
      <img src="../../assets/img/click.png" :class="[isClick ? 'click-img' : 'dis-none']">
    </div>
    <van-popup v-model="show" :close-on-click-overlay="false">
      <div class="submit-dialog">
        <div class="fill-info">
          <div class="fill-ts"></div>
          <div class="fill-draw">
            <img :src="winUrl" class="fill-img"/>
            <div class="fill-text">
              {{ userInfo[activeText.prizeName] }}
            </div>
          </div>
          <div class="fill-field">
            <img src="../../assets/img/name.png" class="info-img"/>
            <van-field v-model="userInfo.winnerName" size="24px" :placeholder="$t('name')"/>
          </div>
          <div class="fill-field">
            <vue-country-code-selector :country-code="countryCode"
                                       @update:countryCode="upDateCode"></vue-country-code-selector>
            <van-field v-model="userInfo.winnerPhone" :placeholder="$t('phone')"/>
          </div>
          <div class="fill-field">
            <img src="../../assets/img/email.png" class="info-img"/>
            <van-field v-model="userInfo.winnerEmail" :placeholder="$t('email')"/>
          </div>
        </div>
        <div class="submit-info flex-center" @click="submitInfo"></div>
        <img src="../../assets/img/close.png" class="close-img" @click="closeDialog"/>
      </div>
    </van-popup>
    <van-popup v-model="showThank" :close-on-click-overlay="false">
      <div class="thank-dialog">
        <div class="thank-info">
          {{ dataInfo.rewardStatus === '1' ? 'Infos envoyées' : dataInfo[activeText.prizeName] }}
        </div>
        <div :class="['thank-bt', dataInfo.ad === 'N' ? 'ad-n' : '']">
          <div class="img-bt" @click="showThank = false">Quitter</div>
          <div class="img-bt" @click="linkAd" v-if="dataInfo.ad === 'Y'">Rejouer</div>
        </div>
        <img src="../../assets/img/close.png" class="close-thank-img" @click="showThank = false"/>
      </div>
    </van-popup>
    <!--  抽奖过程  -->
    <audio ref="audiot" @ended="onEnded" controls class="displyNone">
      <source src="../../assets/mp3/t.mp3" type="audio/mp3">
    </audio>
    <!--  抽中  -->
    <audio ref="audios" controls class="displyNone">
      <source src="../../assets/mp3/s.mp3" type="audio/mp3">
    </audio>
    <!--  失败  -->
    <audio ref="audiof" controls class="displyNone">
      <source src="../../assets/mp3/f.mp3" type="audio/mp3">
    </audio>
  </div>
</template>

<script>
import validation from '../../utils/validation'
import general from '../../utils/general'
import VueCountryCodeSelector from 'vue-country-code-selector/src/index'
import lodash from 'lodash'

export default {
  name: 'draw',
  data () {
    return {
      countryCode: 223,
      isShowMachine: false,
      isClick: true,
      slotWidth: '18rem',
      slotHeight: '7rem',
      slots: [
        {
          speed: 20,
          direction: -1
        },
        {
          speed: 18,
          direction: -1
        },
        {
          speed: 16,
          direction: -1
        }
      ],
      blocks: [
        {
          padding: '3px',
          background: '#FFEDC8',
          borderRadius: '15px'
        },
        {
          padding: '10px',
          background: '#BA0000',
          borderRadius: '15px'
        }
      ],
      prizes: [],
      defaultConfig: {
        rowSpacing: '20px',
        colSpacing: '10px'
      },
      langType: 'fr',
      show: false,
      showThank: false,
      userInfo: {
        winnerNo: '',
        rewardCode: '',
        prizeCode: '',
        prizeName: '',
        prizeFrenchName: '',
        winnerName: '',
        winnerPhone: '',
        winnerEmail: ''
      },
      rewardCode: '',
      winUrl: '',
      winIndex: '',
      prizeArr: [],
      rotationDegree: 0,
      degreesNum: 100, // 转动度数
      rotationInterval: null,
      dataInfo: {}
    }
  },
  beforeDestroy () {
    // 在组件销毁前清除定时器，以防止内存泄漏
    clearInterval(this.rotationInterval)
    // 组件销毁时移除窗口尺寸变化事件监听
    window.removeEventListener('resize', this.handleResize)
  },
  components: {
    VueCountryCodeSelector
  },
  async created () {
    this.rewardCode = this.$route.params.dynamicPart
    window.addEventListener('resize', this.handleResize)
    await this.handleResize()
    await this.getPrizeList()
    await this.getInfoCode()
  },
  computed: {
    activeText () {
      return validation.langText(this.langType)
    }
  },
  methods: {
    // 判断是否中奖
    async getInfoCode () {
      const { data } = await this.$api.game.getInfoCode(this.rewardCode)
      if (data) {
        this.dataInfo = data
        if (data.used === 'Y') {
          if (data.rewardStatus === '0' && data.prizeCode !== 'THANK') {
            this.winUrl = data.prizeImageUrl
            this.userInfo = general.ObjForIn(this.userInfo, data)
            this.show = true
          } else {
            this.showThank = true
          }
        }
      }
    },
    upDateCode (code) {
      this.countryCode = code
    },
    onEnded () {
      this.$refs.audiot.currentTime = 0
      this.$refs.audiot.play()
    },
    // 去看广告
    linkAd () {
      this.$router.push({
        path: '/advertising',
        query: { code: this.dataInfo.newRewardCode }
      })
    },
    // 修改画布宽高
    handleResize () {
      if (window.innerWidth < 375) {
        this.slotWidth = '19rem'
      } else if (window.innerWidth < 400) {
        this.slotWidth = '23rem'
        this.slotHeight = '8.5rem'
      } else {
        this.slotWidth = '26rem'
        this.slotHeight = '10rem'
      }
    },
    getPrizeRewardCode: lodash.debounce(async function () {
      if (!this.isClick) return
      if (this.dataInfo.used === 'Y') {
        await this.getInfoCode()
        return
      }
      const { data } = await this.$api.game.getPrizeRewardCode(this.rewardCode)
      if (typeof data === 'undefined') return
      await this.$refs.audiot.play()
      this.isClick = false
      this.$refs.myLucky.play()
      this.startRotation()
      this.dataInfo = data
      if (data?.prizeCode === 'THANK') {
        setTimeout(() => {
          this.$refs.myLucky.stop([0, Math.floor(Math.random() * (this.prizes.length - 1)) + 2, Math.floor(Math.random() * (this.prizes.length - 1))])
        }, 3000)
        return
      }
      this.prizeArr.forEach((item, index) => {
        if (item.prizeCode === data?.prizeCode) {
          this.userInfo = general.ObjForIn(this.userInfo, data)
          this.winUrl = item.prizeImageUrl
          setTimeout(() => {
            // 调用stop停止旋转并传递中奖索引
            this.$refs.myLucky.stop(index)
          }, 3000)
        }
      })
    }, 1000, { leading: true, trailing: false }),
    // 抽奖
    // 抽奖结束会触发end回调
    endCallback () {
      if (this.dataInfo.prizeCode === 'THANK') {
        this.$refs.audiof.play()
        this.showThank = true
      } else {
        this.$refs.audios.play()
        this.show = true
      }
      this.degreesNum = 100
      this.isClick = true
    },
    // 切换语种
    changeMenu (v) {
      this.langType = v
      sessionStorage.setItem('langType', v)
      this.$refs.langMenu.toggle()
    },
    // 获取奖品列表
    async getPrizeList () {
      const { data } = await this.$api.game.getPrizeList()
      this.prizeArr = data?.list
      this.prizeArr.forEach((item) => {
        this.prizes.push({
          background: '#FFEDC8',
          borderRadius: '10px',
          imgs: [{
            width: '80%',
            top: '2%',
            src: item.prizeImageUrl
          }]
        })
      })
      this.isShowMachine = true
    },
    // 提交奖品信息
    async submitInfo () {
      const d = JSON.parse(JSON.stringify(this.userInfo))
      const n = `+${this.countryCode} ${d.winnerPhone}`
      d.winnerPhone = n
      const { data } = await this.$api.game.submitInfo(d)
      if (data?.status) {
        this.$toast(this.activeText.success)
        this.closeDialog()
      }
    },
    // 弹窗开关
    closeDialog () {
      this.show = !this.show
    },
    startRotation () {
      // 定义定时器，每100毫秒旋转1度
      this.rotationInterval = setInterval(() => {
        if (this.degreesNum === 0) {
          clearInterval(this.rotationInterval)
          this.$refs.audiot.pause()
        }
        this.degreesNum += -2
        this.rotationDegree += this.degreesNum
      }, 100)
    }
  }
}
</script>

<style scoped lang="scss">
.draw {
  overflow: hidden;
  position: relative;

  .displyNone {
    display: none;
  }

  ::v-deep .van-field__control {
    font-size: 36px;
  }

  ::v-deep .van-dropdown-menu {
    width: 100px;
    background: transparent !important;
    position: absolute;
    right: 40px;
  }

  ::v-deep .van-dropdown-menu__title--active {
    color: #303333;
  }

  ::v-deep .van-popup--top {
    width: 300px;
    right: 40px !important;
    left: auto !important;
  }

  .van-dropdown-menu__item {
    background: transparent;
  }

  ::v-deep .van-dropdown-menu__title {
    padding: 0;
  }

  ::v-deep .van-dropdown-menu__bar {
    background: transparent !important;
    box-shadow: none;
  }

  ::v-deep .van-dropdown-menu .van-icon {
    display: none;
  }

  ::v-deep .van-dropdown-menu__title::after {
    border: none;
  }

  .title-item {
    color: #ffffff;

    .title-img {
      width: 20px;
      height: 20px;
      margin-right: 20px;
    }
  }

  .language-menu {
    width: 300px;
    box-sizing: border-box;
    padding: 10px;
    background: #ffffff;

    .language-item {
      display: flex;
      align-items: center;
      color: #cccccc;

      .item-img {
        width: 60px;
        height: 60px;
        margin-right: 20px;
      }
    }
  }

  .machine-pos {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);

    .machine {
    }
  }

  .long-image {
    width: 100%;
    display: block;
    transition: transform 0.3s ease-out;
  }

  .rotate-pos {
    position: absolute;
    left: 50%;
    top: 65%;
    transform: translateX(-50%);

    .rotate-img {
      //width: 100%;
      //height: 90%;
      width: 113px;
      height: 113px;
      transform-origin: center;
      transition: transform 0.5s ease; /* 可选的过渡效果 */
    }

    .click-img {
      width: 57px;
      height: 57px;
      position: absolute;
      top: 78px;
      left: 71px;
    }

    .dis-none {
      display: none;
    }
  }

  ::v-deep .van-popup {
    background: transparent;
  }

  .thank-dialog {
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding-top: 40px;

    .thank-info {
      width: 386px;
      height: 176px;
      background-image: url('../../assets/img/thank.png'); /* 替换为你的图片路径 */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      box-sizing: border-box;
      text-align: center;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 24px;
    }

    .close-thank-img {
      width: 27px;
      height: 27px;
      position: absolute;
      top: 0;
      right: 60px;
      z-index: 9999;
    }

    .thank-bt {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 25px;
      box-sizing: border-box;
      padding: 0 20px;

      .img-bt {
        width: 150px;
        height: 50px;
        background-image: url('../../assets/img/tank-bt.png'); /* 替换为你的图片路径 */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
      }
    }

    .ad-n {
      justify-content: center;
    }
  }

  .submit-dialog {
    .fill-info {
      width: 311px;
      height: 336px;
      background-image: url('../../assets/img/dialog.png'); /* 替换为你的图片路径 */
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      box-sizing: border-box;
      text-align: center;
      overflow: hidden;
      position: relative;

      .fill-ts {
        width: 139px;
        height: 15px;
        margin: 14px auto 0;
        background-image: url('../../assets/img/ts.png'); /* 替换为你的图片路径 */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .fill-draw {
        background-image: url("../../assets/img/goods.png");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        width: 211px;
        height: 134px;
        margin: 26px auto 12px;
        box-sizing: border-box;

        .fill-img {
          width: 67px;
          height: 82px;
          margin: 20px 0 7px;
        }

        .fill-text {
          flex: 1;
          font-size: 12px;
          color: #D51000;
        }
      }

      .fill-field {
        width: 201px;
        height: 32px;
        margin: 0 auto 10px;
        border-radius: 12px;
        background: #ffffff;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0 10px;

        .info-img {
          width: 10px;
          height: 10px;
        }
      }
    }

    .submit-info {
      background-image: url("../../assets/img/submit-bt.png");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 488px;
      height: 110px;
      margin: 36px auto 0;
    }

    .close-img {
      width: 52px;
      height: 52px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
</style>
